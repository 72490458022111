import React from "react";
import {Layout, Image, Row, Space} from 'antd';
import './App.css';
import QueueAnim from 'rc-queue-anim';

const { Content, Footer } = Layout;

function App() {
  return (
    <div className="App">
        <Layout>
            <Content className="banner0">
                <Row justify="center" align="middle" style={{height: "100vh"}}>
                    <Space direction="vertical">
                        <QueueAnim delay={300} type={"bottom"} interval={150} className="queue-simple">
                            <div key="a">
                            <Image
                                src={"https://luxili.oss-cn-shanghai.aliyuncs.com/meta/WechatIMG414.png"}
                                width={200}
                                preview={false}
                            />
                            </div>
                            <div key="b">
                            <Image
                                src={"https://luxili.oss-cn-shanghai.aliyuncs.com/meta/WechatIMG413.png"}
                                width={200}
                                preview={false}
                            />
                            </div>
                        </QueueAnim>
                    </Space>
                </Row>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: '#aaaaaa' }}>Copyright@2019 ANHUI ZHONGCE INVESTMENT GROUP, 安徽中策投资集团版权所有 皖ICP备2021012188号-2</Footer>
        </Layout>
    </div>
  );
}

export default App;
